import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Hero.css";
import { LangContext } from "../../../Context/LangContext";
import { useContext, useState } from "react";
import Model from "../Model/Model";
export default function Hero() {
  const langCtx = useContext(LangContext);
  const [lgShow, setLgShow] = useState(false);

  return (
    <div className="hero" id="hero">
      <div className="main-container">
        <Row className="align-items-center">
          <Col lg="6">
            <div className="text-hero">
              <h2> {text.textHero[langCtx]} </h2>
              <p> {text.desHero[langCtx]}</p>

              <button onClick={() => setLgShow(true)} className="ctm-btn">
                <img src="/images/icon.png" alt="icon" />{" "}
                {text.Booking[langCtx]}{" "}
              </button>
            </div>
          </Col>
          <Col lg="6">
            <div className="img-hero">
              <img src="/images/imgheader.png" alt="imgheader" />
            </div>
          </Col>
        </Row>
      </div>
      <Model
        lgShow={lgShow}
        setLgShow={setLgShow}
        name={text.Booking[langCtx]}
      />
    
    </div>
  );
}

const text = {
  textHero: {
    en: "Moment Care Medical Company ",
    ar: "شركة لحظة الرعاية الطبية",
  },
  desHero: {
    en: "Founded in 2013, Moment Care Medical has grown over 50% each year. We are constantly expanding our site A group of manufacturers and areas of activity. ",
    ar: "تأسست شركة لحظة الرعاية الطبية في عام 2013، وقد نمت بنسبة تزيد عن 50% كل عام. نحن نعمل باستمرار على توسيع موقعنا مجموعة من الشركات المصنعة ومجالات النشاط. ",
  },
  Booking: {
    en: "Order Now",
    ar: "اطلب الآن",
  },
};
