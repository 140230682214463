import { useContext, useEffect, useState } from "react";
import "./Navbar.css";
import { LangContext, LangContextSet } from "../../../Context/LangContext";
import Model from "../Model/Model";

function ListItems(props) {
  return (
    <li>
      <a href={`#${props.id}`}> {props.name} </a>
    </li>
  );
}

function List() {
  const langCtx = useContext(LangContext);

  return (
    <ul>
      <ListItems name={text.Home[langCtx]} id="hero" />
      <ListItems name={text.Services[langCtx]} id="services" />
      <ListItems name={text.Testimonials[langCtx]} id="testimonials" />
      <ListItems name={text.Contactus[langCtx]} id="contactus" />
    </ul>
  );
}

export default function Navbar(props) {
  const [lgShow, setLgShow] = useState(false);

  const langCtx = useContext(LangContext);
  const langSetLang = useContext(LangContextSet);
  const [isOpan, setIsOpan] = useState(false);
  const [scroll, setScroll] = useState(false);
  const handleChangLangContext = () => {
    props.setLoader(false);
    if (langCtx === "ar") {
      langSetLang("en");
      localStorage.setItem("lang", "en");
    } else if (langCtx === "en") {
      langSetLang("ar");
      localStorage.setItem("lang", "ar");
    }
  };

  useEffect(() => {
    window.onscroll = function () {
      if (
        document.body.scrollTop > 20 ||
        document.documentElement.scrollTop > 20
      ) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    };
  }, []);

  return (
    <div className={`top-bar ${scroll ? "active" : ""}`}>
      <div className="main-container">
        <div className="logo">
          <img src="/images/logo.png" alt="logo" />
          <p>{text.Slogan[langCtx]}</p>
        </div>

        <div className={`element ${isOpan ? "active" : ""}`}>
          <di className="logo-res">
            <img src="/images/logo.png" alt="logo" />
          </di>
          <List />

          <button
            onClick={() => setLgShow(true)}
            className="btn-responsive ctm-btn"
          >
            {" "}
            <img src="/images/icon.png" alt="icon" /> {text.Booking[langCtx]}{" "}
          </button>
        </div>

        <div className="btns-top-bar">
          <button onClick={handleChangLangContext}>
            <i className="bi bi-globe2"></i> {langCtx === "ar"? "En" : "ar" } 
          </button>
          <button className="btn-mune-res" onClick={() => setIsOpan(true)}>
            <div className="content">
              <div
                href="#"
                title="Navigation menu"
                className={`navicon ${isOpan ? "is-active" : ""}`}
                aria-label="Navigation"
              >
                <span className="navicon__item"></span>
                <span className="navicon__item"></span>
                <span className="navicon__item"></span>
                <span className="navicon__item"></span>
              </div>
            </div>
          </button>
          <div>
            <button onClick={() => setLgShow(true)} className="ctm-btn">
          
              <img src="/images/icon.png" alt="icon" /> {text.Booking[langCtx]}{" "}
            </button>
          </div>
          <div
            onClick={() => setIsOpan(false)}
            className={`bg-mune  ${isOpan ? "active" : ""}`}
          ></div>
        </div>
      </div>
      <Model
        lgShow={lgShow}
        setLgShow={setLgShow}
        name={text.Booking[langCtx]}
      />
    </div>
  );
}

const text = {
  Home: {
    en: "Home",
    ar: "الرئيسية",
  },
  Services: {
    en: "Services",
    ar: "الخدمات",
  },
  Testimonials: {
    en: "Testimonials",
    ar: "اراء العملاء",
  },

  Contactus: {
    en: "Contactus",
    ar: "تواصل معنا",
  },
  Booking: {
    en: "Order Now",
    ar: "اطلب الآن",
  },
  Slogan: {
    en: "Here in Every Moment",
    ar: "نحن هنا في كل لحظة",
  },
};
