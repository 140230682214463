import "./Footer.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useContext, useState } from "react";
import { LangContext } from "../../../Context/LangContext";
import Terms from "../Terms/Terms";
import Privacy from "../Privacy/Privacy";
import Whatsapp from "../WhatsApp/WhatsApp";
export default function Footer() {
  const langCtx = useContext(LangContext);
  const [lgShowTerms, ShowTerms] = useState(false);
  const [lgShowPrivacy, ShowPrivacy] = useState(false);

  return (
    <footer className="footer pg-section">
      <div className="main-container">
        <Row>
          <Col lg={4}>
            <div className="des-footer">
              <img src="images/logo.png" alt="logo" />
              <p> {text.About_Footer[langCtx]} </p>
            </div>
          </Col>

          <Col lg={4}>
            <div className="element-footer">
              <h2> {text.Site_sections[langCtx]}</h2>
              <ul>
                <li>
                  <a href="#hero"> {text.Home[langCtx]}</a>
                </li>
                <li>
                  <a href="#services"> {text.Services[langCtx]}</a>
                </li>
                <li>
                  <a href="#hero"> {text.AboutUs[langCtx]}</a>
                </li>
                <li>
                  <a onClick={() => ShowTerms(true)} href="#terms">
                    {text.terms[langCtx]}
                  </a>
                </li>
                <li>
                  <a href="#testimonials"> {text.Testimonials[langCtx]}</a>
                </li>
                <li>
                  <a onClick={() => ShowPrivacy(true)} href="#privacy">
                    {text.privacy[langCtx]}
                  </a>
                </li>
              </ul>
            </div>
          </Col>
          <Col lg={4}>
            <div className="sco-media">
              <h2> {text.Follow[langCtx]}</h2>
              <ul>
                <li>
                  <a href="#">
                    <i class="bi bi-whatsapp"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="bi bi-facebook"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="bi bi-instagram"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i class="bi bi-snapchat"></i>
                  </a>
                </li>
              </ul>

              <h4>
                <span> {text.number[langCtx]}: </span> +966-58-307-1540
              </h4>
            </div>
          </Col>
        </Row>

        <div className="copyright">
          <span> {text.copyRight[langCtx]}</span>
        </div>
      </div>
      <Terms
        lgShow={lgShowTerms}
        setLgShow={ShowTerms}
        name={text.terms[langCtx]}
      />
      <Privacy
        lgShow={lgShowPrivacy}
        setLgShow={ShowPrivacy}
        name={text.privacy[langCtx]}
      />
      <div className="bg-footer"> 
      <img style={langCtx ==="ar" ? {  left: "40px"} : {right: "40px"}} src="/images/bgf.png" alt="Moment Care" /> 
      </div>
      <Whatsapp />
    </footer>
  );
}

const text = {
  About_Footer: {
    ar: "تأسست شركة  لحظة الرعاية الطبية  في عام 2013، وقد نمت بنسبة تزيد عن 50% كل عام. نحن نعمل باستمرار على توسيع موقعنا مجموعة من الشركات المصنعة ومجالات النشاط.",
    en: "Moment Care Medical was founded in 2013 and has experienced an annual growth rate exceeding 50%. We continuously strive to expand our presence in various manufacturing sectors and fields of activity. ",
  },
  Site_sections: {
    ar: "أقسام الموقع",
    en: " Site Sections",
  },
  Home: {
    en: "Home ",
    ar: "الرئيسية",
  },
  Services: {
    en: "Services",
    ar: "الخدمات",
  },
  AboutUs: {
    en: "Who We Are",
    ar: " من نحن",
  },
  Testimonials: {
    en: "Testimonials ",
    ar: "اراء العملاء",
  },

  Contactus: {
    en: "Contact Us",
    ar: "تواصل معانا",
  },
  terms: {
    en: "Terms and Conditions",
    ar: "الشروط والأحكام",
  },
  privacy: {
    en: "Privacy Policy",
    ar: "سياسة الخصوصية",
  },
  number: {
    en: "Phone Number",
    ar: "الرقم الموحد",
  },
  Follow: {
    en: " Follow Us",
    ar: " تابعنا",
  },
  copyRight: {
    en: "© 2023 All rights reserved to Moment Care Company.",
    ar: "جميع الحقوق © 2023 محفوظة لـشركة لحظة الرعاية الطبية",
  },
};
